import React from 'react'
import { Col, Container, Row } from 'reactstrap'

const Graph = () => (
  
  <section id="graph">
    <Container>
      <Row>
        <Col>
          <div
            className="seo-h1 text-center text-lowercase mt-0 mb-5"
          >
            <h1 className="m-0">
              Bra, och vad händer nu då?
            </h1>
          </div>
          <ol className="graph">
            <li className="graph-item">
              <span>1</span>
              <div>
                Vi läser ditt meddelande<br/>
                (som vi tackar för!)
              </div>
            </li>
            <li className="graph-item">
              <span>2</span>
              <div>
              Vi svarar dig <br/>
              inom sex timmar
              </div>
            </li>
            <li className="graph-item">
              <span>3</span>
              <div>
              Vi bokar in ett <br/>
              halvtimmeslångt möte <br/>
              med en av våra experter
              </div>
            </li>
            <li className="graph-item">
              <span>4</span>
              <div>
                Du får en offert<br/>
                inom fem arbetsdagar
              </div>
            </li>
            <li className="graph-item">
              <span>5</span>
              <div>
                Är allting till belåtenhet?<br/>
                Då kör vi! 
              </div>
            </li>
          </ol>
        </Col>
      </Row>
    </Container>
  </section>
)

export default Graph
