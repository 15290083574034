import React from 'react'
import { Container, Row } from 'reactstrap'
import InfographicMap from '../../assets/images/infographic-map.jpg'

const Infographic = () => (
  <section id="infographic-numbers" className="infographic">
    <div className="bg-image-wrapper">
      <div className="bg-image d-none d-sm-block" style={{ backgroundImage: `url(${InfographicMap})`}}>
        <div className="bg-overlay" />
      </div>
      <Container className="pb-5 pb-sm-0">
        <Row>
          <div className="col">
            <h1>
              <strong className="text-lowercase">
              förstklassiga webb- och mobilappar 
              </strong>
              <br/>
              direkt från Polens IT-huvudstad
            </h1>
          </div>
        </Row>
        <Row className="pt-4">
          <div className="col-6 col-md-3 info">
            <p className="info-number">
              <strong>30</strong>
            </p>
            <p className="info-text text-lowercase mt-2">medlemmar i vårt team</p>
          </div>
          <div className="col-6 col-md-3 info">
            <p className="info-number">
              <strong>5</strong>
            </p>
            <p className="info-text text-lowercase mt-2">projekt per år</p>
          </div>
          <div className="col-6 col-md-3 info">
            <p className="info-number">
              <strong>7</strong>
            </p>
            <p className="info-text text-lowercase mt-2">år i branschen</p>
          </div>
          <div className="col-6 col-md-3 info">
            <p className="info-number">
              <strong>8</strong>
            </p>
            <p className="info-text text-lowercase mt-2">kundländer</p>
          </div>
        </Row>
      </Container>
    </div>
  </section>
)

export default Infographic
